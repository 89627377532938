import { useMemo } from 'react'
import useSWRInfinite from 'swr/infinite'
import { GetShippingHistoryResponse, ShippingStatusType } from './apiTypes'
import { fetcher } from '../services/http.service'
import { objToQueryString } from '../fns/objectFns'

export function useShippingHistories(tracking_number: string, params?: { page_size: number }) {
  const paramString = useMemo(() => objToQueryString(params), [params])
  const getKey = (pageIndex: number, previousPageData: GetShippingHistoryResponse) => {
    if (pageIndex === 0) return `/shipping/shippingitems/${tracking_number}/history/?${paramString}`
    if (previousPageData && previousPageData.next) return previousPageData.next // reached the end
    return null
  }

  const { data, error, isValidating, setSize, mutate } = useSWRInfinite<GetShippingHistoryResponse>(getKey, fetcher, {
    initialSize: 1,
  })

  const histories = useMemo(() => {
    let listData: ShippingStatusType[] = []
    if (data && data?.length > 0) {
      const lastRes = data[data?.length - 1]
      if (lastRes && lastRes.current_page * lastRes.items_per_page < lastRes.count) setSize(size => size + 1)
      else {
        data.forEach(item => {
          if (item?.results) {
            listData = listData.concat(item.results)
          }
        })
      }
    }
    return listData
  }, [data, params, setSize])

  console.log('deliveries', histories)
  return {
    histories,
    isLoading: isValidating,
    mutate,
    isError: error,
  }
}
